import React, {useContext, useEffect, useState} from "react";
import {GlobalContext} from "./_services/global-context";
import {EmojiSmile} from "react-bootstrap-icons";
import URL from "./_services/routes";
import Api from "./_services/api_service";

export default function PaymentConfirmation() {
    const [global, dispatch] = useContext(GlobalContext);
    const [invoice, setInvoice] = useState('');
    const [order, setOrder] = useState({});

    const order_id = URL.urlQueryParams('order_id')
    const payment_id = URL.urlQueryParams('payment_id')
    useEffect(() => {
        handleGetOrder()
        handleGetOrderInvoice()
    }, []);

    const handleGetOrder = () => {
        const query = `pcl_id=${global.pcl.id}`;
        Api.get(`client/orders/${order_id}/detail?${query}`, (res) => {
            const data = res.data.data
            setOrder(data.order)
            // dispatch({type: "SHOW-SUCCESS", payload: res.data.message})
        }, (errors) => {
            dispatch({type: "SHOW-ERROR", payload: JSON.stringify(errors)})
        });
    }

    const handleGetOrderInvoice = () => {
        const query = `pcl_id=${global.pcl.id}&payment_id=${payment_id || ''}`;
        Api.get(`client/orders/${order_id}/invoice?${query}`, (res) => {
            const data = res.data.data
            setInvoice(data.invoice)
            dispatch({type: "SHOW-SUCCESS", payload: res.data.message})
        }, (errors) => {
            dispatch({type: "SHOW-ERROR", payload: JSON.stringify(errors)})
        });
    }

    return (
        <div className=''>
            <div className='d-flex justify-content-center align-items-center'>
                <div className='text-center'>
                    <div style={{width: '253px'}} className='mb-4 m-auto'>
                        <EmojiSmile className='text-light-green mt-5' style={{fontSize: '77px'}}/>
                    </div>
                    <div className='mt-2 m-auto'>
                        <h3 className=''>Order Completed!</h3>
                        <h3 className='text-light-green'>Thank you, for submitting your order payment.</h3>
                    </div>
                </div>
            </div>

            <div className='d-flex justify-content-center'>
                <div style={{minWidth: '50%'}}>
                    <div className='inner-heading mt-5 text-center fs-26x'>Your order Invoice</div>
                    <div className='mt-2 text-center' dangerouslySetInnerHTML={{__html: invoice}}></div>
                </div>
            </div>


            <div className='w-75 m-auto'>
                Thank you for your booking! To prepare for your visit, everyone must sign a waiver before entering the
                facility.
                Save time and avoid waiting by completing it online now. If your booking includes an event, you can also
                invite guests,
                manage your guest list, and send invitations using our RSVP Manager. Click the links below to complete
                these steps and
                ensure a smooth and enjoyable experience!

                <div className='d-flex justify-content-center gap-3 flex-wrap mt-4 mb-4'>
                    <a className='btn btn-primary btn-md'
                       href={`/waiver-sign`}>SIGN YOUR WAIVER</a>

                    {(order?.total_enable_rsvps > 0) &&
                        <a className='btn btn-success btn-md' href={`/rsvp?order-id=${order_id}`}>MANAGE RSVP</a>
                    }

                    {(order?.print_enabled != 0) &&
                        <a className='btn btn-info btn-md text-white' href={`/prints?order-id=${order_id}`}>
                            Print Passes/Tickets/Vouchers
                        </a>
                    }

                </div>
            </div>

        </div>
    )
}