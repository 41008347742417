import React, {Component} from 'react';
import URL from "./routes";

export default class Services extends Component {

    constructor(props) {
        super(props);
        this.state = {
            global: null,
            dispatch: null,
        };
    }

    componentDidMount() {
        const [global, dispatch] = this.context;
        this.setState({ global, dispatch });
    }

    static getUser() {
        const user = localStorage.getItem("user");
        return (user) ? JSON.parse(user) : {}
    }

    static getToken() {
        const token = localStorage.getItem("token");
        return token ?? null
    }

    static getPcl() {
        const pcl = localStorage.getItem("pcl");
        return (pcl) ? JSON.parse(pcl) : {}
    }

    static getLocation() {
        const location = localStorage.getItem("location");
        return (location) ? JSON.parse(location) : ''
    }

    static getSource() {
        const source = localStorage.getItem("source");
        return (source) ? JSON.parse(source) : ''
    }
    static getOrderId() {
        const order_id = localStorage.getItem("order_id");
        return (order_id) ? JSON.parse(order_id) : ''
    }

    static getCartItemsCount() {
        const cart_items_count = localStorage.getItem("cart_items_count");
        return (cart_items_count) ? JSON.parse(cart_items_count) : 0
    }

    static getCheckoutTime() {
        const checkout_time = localStorage.getItem("checkout_time");
        return (checkout_time) ? JSON.parse(checkout_time) : ''
    }

    static getOrderTime() {
        const order_time = localStorage.getItem("order_time");
        return (order_time) ? JSON.parse(order_time) : ''
    }

    static getCheckoutTimePassed() {
        const checkout_time_passed = localStorage.getItem("checkout_time_passed");
        return (checkout_time_passed) ? JSON.parse(checkout_time_passed) : ''
    }

    static getOrderCustomer() {
        const order_customer = localStorage.getItem("order_customer");
        return (order_customer) ? JSON.parse(order_customer) : 0
    }

    static getRequestedPage() {
        const requested_page = localStorage.getItem("requested_page");
        return (requested_page) ? JSON.parse(requested_page) : ''
    }

    static getShippingAddress() {
        const shipping_address = localStorage.getItem("shipping_address");
        return (shipping_address) ? JSON.parse(shipping_address) : false;
    }

    static getReturningUrls() {
        let returning_urls = localStorage.getItem("returning_urls");
        if (returning_urls) {
            returning_urls = JSON.parse(returning_urls);
        } else {
            returning_urls = [];
            localStorage.setItem("returning_urls", JSON.stringify(returning_urls));
        }
        return returning_urls;
    }

    static setReturningUrl(redirect_url = null) {
        if (!redirect_url) {
            const path = window.location.pathname;
            const params = new URLSearchParams(window.location.search); // Create URLSearchParams object
            params.delete('ref');
            redirect_url = path + (params.toString() ? '?' + params.toString() : '');
        }

        let returningUrls = JSON.parse(localStorage.getItem("returning_urls") || "[]");
        // Remove existing entry if found
        returningUrls = returningUrls.filter(url => url !== redirect_url);
        returningUrls.push(redirect_url);
        localStorage.setItem("returning_urls", JSON.stringify(returningUrls));
    }

    static getReturningUrl() {
        let returning_urls = JSON.parse(localStorage.getItem("returning_urls") || "[]");
        let redirect_url = returning_urls.pop() || "/";
        localStorage.setItem("returning_urls", JSON.stringify(returning_urls));
        const source = JSON.parse(localStorage.getItem("source"));

        if (!redirect_url) redirect_url = "/";
        if (source && source === 'kiosk') {
            if (!redirect_url.includes("kiosk")) {
                redirect_url = "/kiosk" + redirect_url;
            }
        }
        return redirect_url;
    }

    static redirectTo(redirect_url = null, params = '') {
        const source = JSON.parse(localStorage.getItem("source"));
        if (!redirect_url) {
            redirect_url = Services.getReturningUrl();
        } else if (source && source === 'kiosk' && !redirect_url.includes("kiosk")) {
            redirect_url = "/kiosk" + redirect_url;
        }
        if (params) {
            redirect_url = (redirect_url.includes("?")) ? redirect_url + '&' + params : redirect_url + '?' + params
        }
        window.location.assign(redirect_url);
    }

    static clearLoginDetails() {
        localStorage.setItem("token", '');
        localStorage.setItem("user", JSON.stringify({}));
        localStorage.setItem("order_id", JSON.stringify(''));
        localStorage.setItem("order_time", JSON.stringify(""));
        localStorage.setItem("checkout_time", JSON.stringify(""));
        localStorage.setItem("order_customer", JSON.stringify(""));
        localStorage.setItem("cart_items_count", JSON.stringify(0));
        localStorage.setItem("shipping_address", JSON.stringify(false));
    }

    static checkAuthentication () {
        if (!global.token || global.token.trim() === '' || !global.user || Object.entries(global.user).length === 0) {
            window.location.assign('/login')
        }
    }

    static getUrl(url_type = 'web') {
        if (url_type === 'admin') {
            return 'http://localhost:3000'
        } else if (url_type === 'web') {
            return 'https://playtopia.ca'
        } else {
            return '/'
        }
    }

    static getErrorList(errors) {
        let errors_list = [];

        if (errors instanceof Array) {
            errors_list = errors;
        } else if (typeof errors === "object" && errors !== null) {
            // Extract error messages for each field
            errors_list = Object.keys(errors).map(key => errors[key]);
        } else if (typeof errors === "string") {
            errors_list = [errors];
        }

        return (
            <ul className='mb-0 no-list-style' style={{listStyleType: 'none'}}>
                {errors_list.map((error, index) => {
                    if (error instanceof Array || (typeof error === "object" && error !== null)) {
                        return (
                            <li key={index} className='no-list-style' style={{listStyleType: "none", listStyle: 'none'}}>
                                {Services.getErrorList(error)}
                            </li>
                        );
                    } else {
                        let error_msg = error;
                        let string = error_msg ? JSON.stringify(error_msg) : '-';
                        string = (string || '').replace(/^"|"$/g, '');
                        return (
                            <li key={index} className='ps-1 no-list-style' style={{listStyleType: "none", listStyle: 'none'}}>
                                - {string}
                            </li>
                        );
                    }
                })}
            </ul>
        );
    }


}    
