import React, {useContext} from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import {GlobalContext} from "./global-context";

const ProtectedRoute = ({ children }) => {
    const [global, dispatch] = useContext(GlobalContext)
    const location = useLocation();

    if (!global.token || !global.user || !global.user.id) {

        const params = new URLSearchParams(location.search);
        // Check if ref=email exists
        const ref_from_email = params.get('ref') === 'email';
        params.delete('ref');

        // Save full URL including query parameters
        const requested_url = location.pathname + (params.toString() ? '?' + params.toString() : '');

        let returning_urls = (!ref_from_email) ? global.returning_urls : [];
        returning_urls = returning_urls.filter(url => !url.includes(requested_url));
        returning_urls.push(requested_url);
        dispatch({type: 'RETURNING-URLS', payload: returning_urls})
        return <Navigate to="/login" replace />;
    }

    return children;
}

export default ProtectedRoute;