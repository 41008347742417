import React, {useContext, useEffect, useState} from "react";
import URL from "../_services/routes";
import Api from "../_services/api_service";
import {GlobalContext} from "../_services/global-context";
import {Col, Container, Row} from "react-bootstrap";
import pd_image from "../../images/product-dummy-image-1.jpg";
import Services from "../_services/services";
import moment from "moment/moment";
import { useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";
import ItemDetails from "./item";
import Select from "react-select";
import {value} from "lodash/seq";
import {BagCheckFill, Cart, CartCheckFill, XCircleFill} from "react-bootstrap-icons";
import QuickCart from "./quick_cart";

export default function POS({cart, setCart}) {
    const navigate = useNavigate();
    const [global, dispatch] = useContext(GlobalContext);
    const [categories, setCategories] = useState([]);
    const [selectCategories, setSelectCategories] = useState([]);
    const [selectedRootCategory, setSelectedRootCategory] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState({});
    const [mobSelectedCategory, setMobSelectedCategory] = useState({});
    const [properties, setProperties] = useState([]);
    const [quantity, setQuantity] = useState(null);
    const [campaignItem, setCampaignItem] = useState(null);

    let search_val = URL.urlRequest()
    const base_path_exp = (search_val !== '') ? search_val.split('/') : []

    // Effects
    useEffect(() => {
        if (global.pcl.id) {
            loadTopCategories()
        }
    }, [global.pcl.id])

    const loadTopCategories = () => {

        const item = URL.urlQueryParams('item');
        let qryParams = '?level=0&pcl_id=' + global.pcl.id;
        if (item) {
            qryParams += '&item=' + item
        }

        // console.log("Loading top categories:", qryParams);
        Api.get(`client/categories` + qryParams, (res) => {
            const data = res.data
            setCategories(data.categories)
            const options = [];
            data.categories.map(category => {
                const option = {value: category.slug, label: category.name}
                options.push(option)
            })

            setSelectCategories(options)

            if (!search_val) {
                search_val = data.categories[0].slug;
            }

            const default_sel = options.find(o => o.value === search_val);
            setMobSelectedCategory(default_sel || {});

            loadSelectedCategory(search_val);
        }, (errors) => {
            // dispatch({type: "SHOW-ERROR", payload: JSON.stringify(errors)})
        })
    }

    const handleCategorySelectChange = (val) => {
        setMobSelectedCategory(val);
        loadSelectedCategory (val.value)
    }

    const loadSelectedCategory = (category_path) => {
        const item = URL.urlQueryParams('item');
        let qryParams = '?level=2&pcl_id=' + global.pcl.id;
        if (item) {
            qryParams += '&item='+item
        }

        qryParams += '&category=' + category_path;
        category_path = category_path.split('/');
        if (category_path.length) {
            setSelectedRootCategory(category_path[0]);
        }

        Api.get(`client/categories` + qryParams, (res) => {
            const data = res.data;
            setSelectedCategory(data)
            if (category_path.length === 1) {
                navigate('/pos/' + category_path[0]);
            }


            console.log('category_path.length: ', category_path.length)
            const last_index = (category_path.length > 0) ? category_path.length - 1 : 0;
            if (category_path.length > 1 && last_index && category_path[last_index] === 'add-ons' && data?.items && data.items.length === 0) {
                // navigate('');
                window.location.assign('/pos/add-ons')
            }

            console.log('ASD', last_index, category_path[last_index])
            if (category_path.length === 1 && category_path[last_index] === 'add-ons' && data?.items && data.items.length === 0) {
                const url = Services.getReturningUrl();
                window.location.assign(url)
            }

        }, (errors) => {
            // dispatch({type: "SHOW-ERROR", payload: JSON.stringify(errors)})
        })
    }

    const handleCheckProperties = (category_id, item) => {
        const item_id = item.id;
        const min_qty_per_order = item.min_qty_per_order;
        const qty = quantity || min_qty_per_order || 1;
        if (item.property_count) {
            Services.setReturningUrl();
            window.location.assign(`/cart/add?category_id=${category_id}&item_id=${item_id}&quantity=${qty}`);
        }else {
            handleAddToCart(category_id, item_id, qty)
        }
    }

    const handleAddToCart = (category_id, item_id, i_quantity) => {
        const item = '';

        const payload = {
            pcl_id: global.pcl.id,
            item_id: item_id,
            category_id: category_id,
            order_id: global.order_id || '',
            quantity: i_quantity
        }

        Api.post(`client/order-details`, payload, (res) => {
            const data = res.data.data;

            const cart_items_count = Number(global.cart_items_count) + 1;
            dispatch({type: "ORDER-ID", payload: data.order_id})
            dispatch({type: 'ORDER-CUSTOMER', payload: data.customer_id ?? 0});
            dispatch({type: "ORDER-TIME", payload: moment().format('YYYY-MM-DD HH:mm')})
            dispatch({type: 'CART-ITEMS-COUNT', 'payload': cart_items_count})
            dispatch({type: "SHOW-SUCCESS", payload: 'Item added into cart successfully.'})

            if (data.campaign_item && Object.keys(data.campaign_item).length > 0) {
                setCampaignItem(data.campaign_item)
                handleCampaignItem(data.order_id, data.campaign_item, cart_items_count);
            } else {
                setCampaignItem({})
            }

        }, (errors) => {
            dispatch({type: "SHOW-ERROR", payload: Services.getErrorList(errors)})
        })
    }

    const handleCampaignItem = (order_id, campaign_item, cart_items_count) => {
        if (Object.keys(campaign_item).length > 0 && campaign_item['item_quantity'] > 0) {
            let discount_type = (campaign_item['camp_discount_type'] == 'exact_discount') ? '$' : '%'

            const data = {
                pcl_id: global.pcl.id,
                order_id: order_id,
                item_id: campaign_item['camp_item_id'],
                category_id: campaign_item['camp_category_id'],
                price_id: campaign_item['price_id'],
                price_detail_id: campaign_item['price_detail_id'],
                quantity: campaign_item['item_quantity'],
                request_type: 'item-add-to-cart',
            }

            Swal.fire({
                toast: true,
                title: 'Congrats!',
                html: "<div>" +
                    "You have an extra discounted item with this item purchase." + "<br>" +
                    "<span class='text-success'>Item Name: " + campaign_item['item_title'] + " </span><br>" +
                    "<span class='text-danger'>Discount: " + campaign_item['camp_discount'] + discount_type + " </span><br>" +
                    "<span class='text-warning'>Item Price: $" + campaign_item['camp_item_regular_price'] + " </span><br>" +
                    "<span class='text-info'>Discount Quantity: " + campaign_item['item_quantity'] + " </span><br>" +
                    "</div>",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#1daf52',
                cancelButtonColor: '#b49311',
                confirmButtonText: 'Yes, Add Item',
                width: '600px'
            }).then((result) => {
                if (result.isConfirmed) {
                    Api.post(`client/order-details`, data, (res) => {
                        const data = res.data.data
                        dispatch({type: 'CART-ITEMS-COUNT', 'payload': Number(cart_items_count) + 1})
                        dispatch({type: "SHOW-SUCCESS", payload: res.data.message})
                    }, (errors) => {
                        dispatch({type: "SHOW-ERROR", payload: JSON.stringify(errors)})
                    })
                } else {
                    setCampaignItem({})
                }
            })

        }
    }

    function renderCategory(category) {
        if (category.categories === undefined || category.categories.length == 0 && category.items.length == 0) {
            return null;
        }

        const category_path_name = category.path.split("/")
            .map(segment => segment.split("-").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ")
            ).join("/");

        // style={{maxWidth: '327px', minWidth: '277px'}}
        return (
            <>
                {(category?.items.length > 0 && category.path && category.path !== '' && category.path !== ' ')&&
                    <div style={{width: '100%'}} className='mt-3'><h2 className='bb-1px text-center text-green'>{category_path_name}</h2></div>
                }

                {category.items && category.items.map(item => {
                    const large_columns = (global.cart_items_count > 0) ? 'col-lg-6 col-xl-4' : 'col-lg-4 col-xl-3';
                    const is_discount = (item.prices && item.prices.discount_price && item.prices.discount_title)
                    // Col; className={`col-12 col-md-6 ${large_columns} mt-3 mb-3`}
                    let image_url = null;
                    if (item?.images && item.images.length > 0) {
                        image_url = item.images[0];
                    }
                    image_url = image_url?.url?.trim() || pd_image;
                    return (
                        <div key={item.id} className={`item-wrapper`}>
                           <div className='item position-relative' style={{borderRadius: '10px'}}>
                                <div className='item-img-wrapper position-relative'
                                     style={{ backgroundImage: `url(${encodeURI(image_url)})` }}>
                                    {/*<img className='item-img rounded-top' src={(item.images && item.images.url) ? item.images.url : pd_image}/>*/}
                                    {(is_discount) &&
                                        <div className='on-sale-item'>On Sale</div>
                                    }
                                </div>
                                <div className='rounded-bottom p-2 border-secondary'>
                                    <div className='item-detail mb-1'>
                                        <div className='fw-bold fs-22x'> {item.name}</div>
                                    </div>

                                    <div className='d-flex justify-content-center align-items-center gap-2 fw-bold fs-18x mb-1'>
                                        {(item.prices && item.prices.discount_price && item.prices.discount_title) ?
                                            <div className='text-center'>
                                                <del>${Number(item.prices.regular_price).toFixed(2)}</del>&nbsp;
                                                <span className='text-green'>${Number(item.prices.applied_price).toFixed(2)}</span>

                                                <span className='text-danger'> ({item?.prices?.discount_title?.slice(0, 16)}
                                                    {(item?.description?.length > 16)&&'...'})
                                                </span>
                                                {(item.prices.description) &&
                                                    <span className='text-dark-light fs-14x'> ({item.prices.description})</span>
                                                }
                                            </div>
                                            :
                                            <>${Number(item.prices.regular_price).toFixed(2)} &ensp;</>
                                        }
                                    </div>

                                    <div className="text-center" style={{marginBottom: '53px'}}>
                                        <div className='fs-17x'> {item?.description?.slice(0, 37)}
                                            {(item?.description?.length > 37)&&'...'}&ensp;
                                            <a href={`${category.path}/${item.slug}`}
                                               className='d-inline text-decoration-none'>View detail</a>
                                        </div>
                                    </div>
                                </div>

                               {/* Item footer section */}
                               <div className='item-footer-section'>
                                   {/*<div className='display: flex; justify-content: center; align-items: center; gap: 10px;'>*/}
                                   <div className='d-block'>
                                       {/*<div className='d-inline-block me-2'>Quantity:</div>*/}
                                       <select className='form-select d-inline-block me-2 rounded-pill' style={{maxWidth: '92px'}}
                                               onChange={(e) => setQuantity(e.target.value)}>
                                           <option selected value="1">1</option>
                                           {(() => {
                                               let elements = [];
                                               for (let i = 1; i <= 20; i++) {
                                                   if (i < item.min_qty_per_order || item.sell_as_single_item_per_line > i) continue;
                                                   if (i > item.max_qty_per_order || i > item.max_sellable_qty) {
                                                       break;
                                                   }

                                                   elements.push(<option key={i} value={i}>{i}</option>);

                                                   if ((item.sell_as_single_item_per_line || item.sell_as_single_item_per_order)) {
                                                       break;
                                                   }
                                               }
                                               return elements;
                                           })()}
                                       </select>

                                       <button className='btn btn-add-cart rounded-pill'
                                               onClick={() => handleCheckProperties(category.id, item)}>
                                           <div className='d-flex align-items-center'>
                                               <BagCheckFill size={20} className=''/> <div style={{marginTop: '2px'}}> &nbsp;Add To Cart</div>
                                           </div>
                                           </button>
                                   </div>
                               </div>

                            </div>
                        </div>
                    )
                    }
                )}

                {category.categories && category.categories.map(subCategory => (
                    <React.Fragment key={subCategory.id}>
                        {renderCategory(subCategory)}
                    </React.Fragment>
                ))}
            </>
        );
    }

    const targetHeight = 57
    const selectStyles = {
        control: (base) => ({
            ...base,
            minHeight: 'initial',
            backgroundColor: '#55c192'
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: '#ffffff',
            fontWeight: 'normal',
            fontSize: '22px'
        }),
        valueContainer: (base) => ({
            ...base,
            height: `${targetHeight - 1 - 1}px`,
            padding: '0 8px',
            fontSize: '22px',
        }),
        clearIndicator: (base) => ({
            ...base,
            padding: `${(targetHeight - 20 - 1 - 1) / 2}px`,
        }),
        dropdownIndicator: (base) => ({
            ...base,
            padding: `${(targetHeight - 20 - 1 - 1) / 2}px`,
        }),
        menuList: (provided) => ({
            ...provided,
            backgroundColor: '#6fcd98',
            padding: '0px'
        }),
        option: (provided, state) => ({
            ...provided,
            fontSize: '22px',
            backgroundColor: state.isFocused || state.isSelected
                ? '#ed7b72'
                : provided.backgroundColor,
            color: '#ffffff',
            borderBottom: '1px solid #ffffff',
        }),
    }

    console.log('categories: ', selectedCategory, categories)
    // Render main content
    return (
        <div className='container-fluid pt-3'>

            <div>
                <div className='hide-on-mobile'>
                    <div className='d-flex flex-wrap gap-2 mb-4 justify-content-center'>
                        {categories && categories.map((category, cIndex) => {
                            let active = ''
                            if (category.slug === selectedRootCategory) {
                                active = 'active'
                            }

                            // d: flex-1 category
                            // a: btn-category ${active}
                            // a: href={`/${category.slug}`}
                            return (
                                <div key={cIndex} className={`category ${active}`} data-tooltip-id='tooltip' data-tooltip-content={category.name}
                                     onClick={(e) => { e.preventDefault(); loadSelectedCategory(category.slug); }} >
                                    <div
                                        className={`category-link`}>
                                        {category?.name?.slice(0, 16)}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>

                <div className='hide-on-desktop mb-3'>
                    <div style={{width: '100%', textAlign: 'center'}}>
                        <label className='mb-2 mt-3 fw-bold fs-22x text-green'>Select Your Category</label>
                        <Select styles={selectStyles} className='category-select' placeholder="Select Category"
                                value={mobSelectedCategory}
                                options={selectCategories}
                                onChange={(val) => handleCategorySelectChange(val)}
                        />
                    </div>
                </div>
            </div>
            <Row>
                <Col className=''>
                    <div className='d-flex flex-wrap justify-content-center gap-4'>
                        {/* Load category tree */}


                        {/* Load single item */}
                        {selectedCategory && selectedCategory?.categories?.length == 0 && selectedCategory?.items?.length == 1 ? (
                            <ItemDetails path={selectedCategory.path + '/' + selectedCategory.items[0].slug}
                                         setQuantity={setQuantity} handleCheckProperties={handleCheckProperties}/>
                        ) : (
                            selectedCategory && renderCategory(selectedCategory)
                        )}
                    </div>

                    {(!categories || Object.entries(categories).length === 0) &&
                        <div className='d-flex justify-content-center fw-bold fs-19x'>No categories or items found.</div>
                    }
                </Col>

                {(global.cart_items_count >= 1)&&
                    <QuickCart/>
                }
            </Row>

        </div>
    )
}