import React, {createContext, useReducer} from "react";

export const GlobalContext = createContext(null);

const reducer = (state, action) => {
    let payload_new = null;
    switch (action.type) {
        case "TOKEN":
            localStorage.setItem("token", action.payload);
            return {
                ...state,
                token: action.payload
            }
        case "USER":
            localStorage.setItem("user", JSON.stringify(action.payload));
            return {
                ...state,
                user: action.payload
            }
        case "SETTINGS":
            localStorage.setItem("settings", JSON.stringify(action.payload));
            return {
                ...state,
                settings: action.payload
            }
        case "PCL":
            localStorage.setItem("pcl", JSON.stringify(action.payload));
            return {
                ...state,
                pcl: action.payload
            }
        case "LOCATION":
            localStorage.setItem("location", JSON.stringify(action.payload));
            return {
                ...state,
                location: action.payload
            }
        case "ORDER-ID":
            localStorage.setItem("order_id", JSON.stringify(action.payload));
            return {
                ...state,
                order_id: action.payload
            }
        case "CART-ITEMS-COUNT":
            localStorage.setItem("cart_items_count", JSON.stringify(action.payload));
            return {
                ...state,
                cart_items_count: action.payload
            }
        case "ORDER-TIME":
            localStorage.setItem("order_time", JSON.stringify(action.payload));
            return {
                ...state,
                order_time: action.payload
            }
        case "CHECKOUT-TIME":
            localStorage.setItem("checkout_time", JSON.stringify(action.payload));
            return {
                ...state,
                checkout_time: action.payload
            }
        case "CHECKOUT-TIME-PASSED":
            localStorage.setItem("checkout_time_passed", JSON.stringify(action.payload));
            return {
                ...state,
                checkout_time_passed: action.payload
            }
            case "ORDER-CUSTOMER":
            localStorage.setItem("order_customer", JSON.stringify(action.payload));
            return {
                ...state,
                order_customer: action.payload
            }
        case "REQUESTED-PAGE":
            localStorage.setItem("requested_page", JSON.stringify(action.payload));
            return {
                ...state,
                requested_page: action.payload
            }
        case "SHIPPING-ADDRESS":
            localStorage.setItem("shipping_address", JSON.stringify(action.payload));
            return {
                ...state,
                shipping_address: action.payload
            }
        case "SOURCE":
            localStorage.setItem("source", JSON.stringify(action.payload));
            return {
                ...state,
                source: action.payload
            }
        case "RETURNING-URLS":
            localStorage.setItem("returning_urls", JSON.stringify(action.payload));
            return {
                ...state,
                returning_urls: action.payload
            }
        case "SHOW-SUCCESS":
            payload_new = {type: "success", msg: action.payload}
            return {
                ...state,
                alert: payload_new
            }
        case "SHOW-WARNING":
            payload_new = {type: "warning", msg: action.payload}
            return {
                ...state,
                alert: payload_new
            }
        case "SHOW-ERROR":
            payload_new = {type: "danger", msg: action.payload}
            return {
                ...state,
                alert: payload_new
            }
        default:
            payload_new = {type: "danger", msg: "Unknown dispatch action: " + action.type}
            return {
                ...state,
                alert: payload_new
            }
    }
}

export const GlobalContextProvider = (props) => {
    const [state, dispatch] = useReducer(reducer, props.init);

    return (
        <GlobalContext.Provider value={[state, dispatch]}>
            {props.children}
        </GlobalContext.Provider>
    )
}

