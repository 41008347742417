import React, {useContext, useEffect, useState} from "react";
import {GlobalContext} from "../_services/global-context";
import logo_image from "../../images/playtopia-logo-1.png";
import URL from "../_services/routes";
import Services from "../_services/services";
import {Alert} from "react-bootstrap";
import {useLocation, useNavigate} from "react-router-dom";

export default function KioskWaiver() {
    const [global, dispatch] = useContext(GlobalContext)
    const [response, setResponse] = useState(null)

    const navigate = useNavigate();
    const location = useLocation();
    const res = URL.urlQueryParams('response');
    useEffect(() => {
        // Logout the sessions
        // Services.clearLoginDetails()

        if (res && res.trim() !== '') {
            const msg = 'You have successfully signed the waiver!';
            setResponse(msg)
            dispatch({type: "SHOW-SUCCESS", payload: msg})
        }

        setTimeout(() => {
            setResponse(null)
            removeQueryParam('response')
        }, 5000);
    }, []);

    const removeQueryParam = (param) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.delete(param);
        navigate({pathname: location.pathname, search: searchParams.toString()}, {replace: true});
    }


    const handleUpdateWaiver = () => {
        Services.setReturningUrl('/waiver-sign')
        if (global.user && global.user.id && global.token) {
            window.location.assign('/kiosk/waiver-sign?waiver=update')
        } else {
            window.location.assign('/kiosk/login?waiver=update')
        }
    }

    return (
        <div className='kiosk-bg-img d-flex justify-content-center'>
            <div className='text-center' style={{marginTop: '40px'}}>

                {(res && response) &&
                    <div className='mt-3'>
                        <Alert variant='success'>
                            <div className='fs-22x fw-bold'>{response}</div>
                        </Alert>
                    </div>
                }

                <div style={{width: '253px'}} className='mb-4 m-auto'>
                    <img style={{width: '100%'}} src={global.pcl.dark_logo || logo_image} alt='Playtopia'/>
                </div>

                <a className='btn btn-primary btn-lg me-3 mb-3' style={{minWidth: '276px'}}
                   href={`/kiosk/waiver-sign?waiver=new`}>SIGN A NEW WAIVER</a>

                <button className='btn btn-primary btn-lg mb-3' style={{minWidth: '276px'}}
                        onClick={() => handleUpdateWaiver()}>UPDATE PREVIOUS WAIVER
                </button>
            </div>
        </div>
    )
}