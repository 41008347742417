import React, {useContext, useEffect, useState} from "react";
import {GlobalContext} from "../_services/global-context";
import logo_image from "../../images/playtopia-logo-1.png";
import {Alert} from "react-bootstrap";
import URL from "../_services/routes";
import Services from "../_services/services";

export default function Waiver() {
    const [global, dispatch] = useContext(GlobalContext)
    const [response, setResponse] = useState(null)

    const res = URL.urlQueryParams('response');
    useEffect(() => {

        if (res) {
            const msg = 'You have successfully signed the waiver!';
            setResponse(msg)
            dispatch({type: "SHOW-SUCCESS", payload: msg})
        }

    }, []);

    const handleSelectWaiver = (type = 'new') => {
        if (!global.source && global.source !== 'kiosk') {
            Services.setReturningUrl('/waiver')
        }

        if (type === 'new') {
            window.location.assign('/waiver-sign?waiver=new')
        }else {
            if (global.user && global.user.id && global.token) {
                window.location.assign(`/waiver-sign?waiver=${type}`)
            } else {
                Services.setReturningUrl('/waiver-sign?waiver=update')
                window.location.assign((global.source && global.source === 'kiosk') ? '/kiosk/login' : '/login')
            }
        }
    }

    return (
        <div className='kiosk-bg-img d-flex justify-content-center'>
            <div className='text-center' style={{marginTop: '40px'}}>

                {(res && response) &&
                    <div className='mt-3'>
                        <Alert variant='success'>
                            <div className='fs-24x fw-bold'>{response}</div>
                        </Alert>
                    </div>
                }

                <div style={{width: '253px'}} className='mb-4 m-auto'>
                    <img style={{width: '100%'}} src={global.pcl.dark_logo || logo_image} alt='Playtopia'/>
                </div>

                <div className='d-flex align-items-center justify-content-center gap-2 flex-wrap'>
                    <button className='btn btn-primary btn-lg mb-3' style={{minWidth: '276px'}}
                            onClick={() => handleSelectWaiver('new')}>SIGN A NEW WAIVER
                    </button>

                    <button className='btn btn-primary btn-lg mb-3' style={{minWidth: '276px'}}
                            onClick={() => handleSelectWaiver('update')}>UPDATE PREVIOUS WAIVER
                    </button>
                </div>
            </div>
        </div>
    )
}