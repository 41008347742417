import React, {useContext, useEffect, useState} from "react";
import {Container, Nav} from 'react-bootstrap';
import {
    ArrowRightShort,
    BoxArrowInRight,
    Clock,
    Person,
    PersonAdd,
    PersonUp,
    PhoneVibrate
} from "react-bootstrap-icons";
import {GlobalContext} from "../_services/global-context";

function TopBar() {
    const [global, dispatch] = useContext(GlobalContext)
    const [token, setToken] = useState(global.token || null)

    useEffect(() => {

    }, [global.token]);

    return (
        <div className='top-bar d-flex align-items-center'>
            <Container>
                <div className='d-flex justify-content-between align-items-center'>
                    <div className='d-flex gap-3'>
                        <div><span className=''><PhoneVibrate/></span>1-905-695-2511</div>
                        <div className='br-1px'></div>
                        <div><span className=''><Clock/></span> Opening Hours:</div>
                    </div>
                    <div className='d-flex gap-3'>
                        {(global.token && global.user && global.user.id) ?
                            <a href="/logout" className='text-decoration-none text-white'><BoxArrowInRight/> Sign out</a>
                            :
                            <>
                                {/*<a href="/login" className='text-decoration-none text-white'><Person/> Sign in</a>*/}
                                <a href="/register" className='text-decoration-none text-white'><PersonAdd/> Register</a>
                            </>
                        }
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default TopBar;
