import React, {useContext, useEffect, useState} from "react";
import {GlobalContext} from "../_services/global-context";
import {Tab, Row, Col, Container} from "react-bootstrap";
import Profile from "./profile";

export default function Account() {
    const [global, dispatch] = useContext(GlobalContext);
    const [activeTab, setActiveTab] = useState('my-orders');

    useEffect(() => {

    }, [])

    return (
        <Container className='mt-4'>
            <Tab.Container id="left-tabs-example" defaultActiveKey="my-orders">
                <Row>
                    {/*
                    <Col sm={2}>
                        <div className='custom-nave-pills-header'>Menu</div>
                        <Nav variant="pills" className="flex-column custom-nave-pills">
                            <Nav.Item><Nav.Link eventKey="my-orders" onClick={() => setActiveTab('my-orders')}>
                                <ViewList className='fw-bold fs-20x'/> My Orders
                            </Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link eventKey="my-waivers" onClick={() => setActiveTab('my-waivers')}>
                                <BorderStyle className='fw-bold fs-20x'/> My Waivers
                            </Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link eventKey="my-profile" onClick={() => setActiveTab('my-profile')}>
                                <PersonFillGear className='fw-bold fs-20x'/> My Profile
                            </Nav.Link></Nav.Item>
                        </Nav>
                    </Col>
                    */}
                    <Col sm={12}>
                        <Profile/>
                        {/*
                        <Tab.Content>
                            {(activeTab === 'my-orders') &&
                                <Tab.Pane eventKey="my-orders"><Orders/></Tab.Pane>
                            }
                            {(activeTab === 'my-waivers') &&
                                <Tab.Pane eventKey="my-waivers"><Waivers/></Tab.Pane>
                            }
                            {(activeTab === 'my-profile') &&
                                <Tab.Pane eventKey="my-profile"><Profile/></Tab.Pane>
                            }
                        </Tab.Content>
                        */}
                    </Col>
                </Row>
            </Tab.Container>
        </Container>
    )
}