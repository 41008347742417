import React, {useState, useEffect, useContext} from 'react';
import {Alert, Button, Container} from 'react-bootstrap';
import Api from "../_services/api_service";
import Services from "../_services/services";
import {GlobalContext} from "../_services/global-context";
import URL from "../_services/routes";
import logo_image from "../../images/playtopia-logo-1.png";

const Login = () => {
    const [global, dispatch] = useContext(GlobalContext);
    const [processing, setProcessing] = useState(false);
    const [error_message, setErrorMessage] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState('0');
    const [access, setAccess] = useState(true);

    const user_found = URL.urlQueryParams('found');
    const redirect_base = URL.getRedirectBase();

    useEffect(() => {
        dispatch({type: 'USER', payload: {}});
        dispatch({type: 'TOKEN', payload: ''});
    }, []);

    const handleInputChange = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        switch (name) {
            case 'username':
                setUsername(value);
                break;
            case 'password':
                setPassword(value);
                break;
            case 'rememberMe':
                setRememberMe(value);
                break;
            // Add cases for other input fields if needed
            default:
                break;
        }
    }

    const handleLogin = (e = undefined, OTP = true) => {
        if (e) e.preventDefault();

        const payload = {
            pcl_id: global.pcl.id,
            email: username,
            password: password,
            browser_id: global.browser_id,
        };

        let error_message = '';
        setProcessing(true);
        setErrorMessage(error_message);

        Api.post('client/auth/login', payload, (res) => {
            const data = res.data.data;
            if (data.token) {
                dispatch({type: "USER", payload: data.user});
                dispatch({type: "TOKEN", payload: data.token});
                dispatch({type: 'ORDER-CUSTOMER', payload: data.user.id});
                localStorage.setItem('user_pcl', JSON.stringify(data.pcl));

                dispatch({type: "SHOW-SUCCESS", payload: 'Logged in successfully!'})
            } else {
                dispatch({type: "SHOW-ERROR", payload: 'Sorry, Invalid login details!'})
            }

            setTimeout(() => {
                if (data.token) {
                    let returning_url = Services.getReturningUrl();
                    if (!returning_url) returning_url = '/';
                    window.location.assign(returning_url)
                }
            }, 2000)

        }, (errors) => {
            error_message = errors;
            if (errors.response && errors.response.data) {
                if (errors.response.data.message && errors.response.data.message !== '') {
                    error_message = errors.response.data.message;
                }
            }

            if (typeof error_message === 'object') {
                error_message = JSON.stringify(error_message)
            }

            setProcessing(false);
            dispatch({type: "SHOW-ERROR", payload: error_message})
        });
    }

    // console.log('waiver: ', waiver)
    return (
        <div className=' min-vh-100'>
            <Container>
                <div className="auth-wrapper mt-4">

                    <div className="auth-inner text-center">
                        <div style={{width: '133px'}} className='mb-2 m-auto'>
                            <img style={{width: '100%'}} src={global.pcl.dark_logo || logo_image} alt='Playtopia'/>
                        </div>

                        <form onSubmit={(e) => handleLogin(e, false)}>
                            <h3>Login to your account!</h3>
                            {error_message !== '' && (
                                <div className="text-center fs-17x mb-3">
                                    <span style={{color: 'red'}}>{error_message}</span>
                                </div>
                            )}

                            {(user_found && global.user && global.user.id)&&
                            <div>
                                <Alert variant='success'>
                                    You already have the account, please login to sign the waiver.
                                </Alert>
                            </div>
                            }

                            <div style={{textAlign: 'left'}}>
                                <div className="form-group">
                                    <label>Email address</label>
                                    <input type="email" disabled={!access} className="form-control"
                                           placeholder="Enter email" name="username"
                                           value={username}
                                           onChange={handleInputChange}
                                    />
                                </div>

                                <div className="form-group">
                                    <label>Password</label>
                                    <input type="password" disabled={!access} className="form-control"
                                           placeholder="Enter password" name="password"
                                           onKeyDown={(e) => e.key === 'Enter' && handleLogin(false)}
                                           value={password}
                                           onChange={handleInputChange}
                                    />
                                </div>
                                <br/>
                                <div className='d-flex justify-content-center gap-2'>

                                    <button type="submit" disabled={!access} className="btn btn-success btn-block">
                                        Sign In {processing && <span>&nbsp;processing...</span>}
                                    </button>

                                    <Button size='md' type="button" variant="warning" className='text-white'
                                            onClick={() => Services.redirectTo()}>Cancel</Button>

                                </div>

                                <div className='text-center mt-4'>
                                    <a href={`${redirect_base}/register${(global.returning_url)?`?rd=${global.returning_url}`:''}`} className='text-decoration-none me-3'>Register Account</a>
                                    <a href={`${redirect_base}/reset-password${(global.returning_url)?`?rd=${global.returning_url}`:''}`} className='text-decoration-none text-warning'>
                                        Reset Password</a>
                                </div>
                            </div>
                        </form>

                    </div>
                    <br/>
                </div>
            </Container>
        </div>
    );
};

export default Login;
