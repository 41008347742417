import React, {useContext, useEffect, useState} from "react";
import {GlobalContext} from "./_services/global-context";
import moment from "moment";
import Api from "./_services/api_service";
import Services from "./_services/services";

export default function CheckoutTimer() {
    const [global, dispatch] = useContext(GlobalContext)
    const [timeLeft, setTimeLeft] = useState(0);
    const [timePassed, setTimePassed] = useState(false);

    useEffect(() => {
        // check if times expires then cleanup the cart values
        handleExpiryTimes();

        if (!global.order_id) {
            handleClearCartDetails();
        }

        let is_cValid_time = moment(global.checkout_time, 'YYYY-MM-DD HH:mm', true).isValid();
        if (global.order_time && global.checkout_time && is_cValid_time) {
            const interval = setInterval(updateCounter, 1000);
            return () => clearInterval(interval);
        }
    }, [global.order_id, global.checkout_time, global.order_time]);


    const handleExpiryTimes = () => {
        let expired = false;
        const now = moment();
        let is_oValid_time = moment(global.order_time, 'YYYY-MM-DD HH:mm', true).isValid();

        if (global.order_time && !is_oValid_time) {
            expired = true;
        }

        if (!expired && ((global.order_id && !global.order_time) || (!global.order_id && global.order_time))) {
            expired = true
        }

        if (!expired && global.order_time) {
            let order_time = Math.abs(moment(global.order_time).diff(now, 'seconds'));
            order_time = (order_time / 60).toFixed(4);
            if (order_time > 120) {
                expired = true
            }
        }

        let checkout_time_expires = false;
        let is_cValid_time = moment(global.checkout_time, 'YYYY-MM-DD HH:mm', true).isValid();
        if (global.checkout_time && !is_cValid_time) {
            checkout_time_expires = true
        }

        if (global.checkout_time ) {
            let checkout_time = Math.abs(moment(global.checkout_time).diff(now, 'seconds'));
            checkout_time = (checkout_time / 60).toFixed(4);
            if (checkout_time > 20) {
                expired = true
                checkout_time_expires = true
            }
        }

        if ((expired || checkout_time_expires) && (global.order_id || global.checkout_time || global.order_time || global.cart_items_count > 0)) {
            // console.log('---- EXPIRED ----')
            if (global.order_id && checkout_time_expires) handleBookingExpired(global.order_id)

            // return false;
            setTimeLeft(0)
            setTimePassed(true);
            dispatch({type: 'ORDER-ID', payload: ''})
            dispatch({type: 'ORDER-TIME', payload: ''})
            dispatch({type: 'CHECKOUT-TIME', payload: ''})
            dispatch({type: 'CART-ITEMS-COUNT', payload: 0})
            dispatch({type: 'SHIPPING-ADDRESS', payload: false})
        }
    }


    const updateCounter = () => {
        if (global.checkout_time) {
            const now = moment();
            const targetTime = moment(global.checkout_time);
            const remainingSeconds = targetTime.diff(now, 'seconds');

            if (remainingSeconds > 3) {
                setTimeLeft(remainingSeconds);
            } else {
                setTimeLeft(0);
                setTimePassed(true);
                handleClearCartDetails(global.order_id);
            }
        }else if (timeLeft !== 0) {
            setTimeLeft(0);
            setTimePassed(true);
            handleClearCartDetails(global.order_id);
        }

    }

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    }

    const handleExtendTime = (e) => {
        e.preventDefault();

        const p_time = (global.checkout_time) ? moment(global.checkout_time) : moment();
        let new_time = p_time.add(10, 'minutes');
        new_time = moment(new_time).format('YYYY-MM-DD HH:mm')
       dispatch({type: 'CHECKOUT-TIME', payload: new_time})

        const data = {
            pcl_id: global.pcl.id,
            booking_expiry_extend: 10,
        }

        // booking_expiry_extend
        Api.put(`client/orders/${global.order_id}`, data, (res) => {
            const data = res.data.data

        }, (errors) => {
            dispatch({type: "SHOW-ERROR", payload: Services.getErrorList(errors)})
        })

    }

    const handleBookingExpired = (order_id = global.order_id) => {
        if (order_id) {
            const data = {
                pcl_id: global.pcl.id,
                booking_expired: true,
            }

            // booking_expiry_extend
            Api.put(`client/orders/${order_id}`, data, (res) => {
                const data = res.data.data
            }, (errors) => {
                // dispatch({type: "SHOW-ERROR", payload: Services.getErrorList(errors)})
            })
        }
    }

    const handleClearCartDetails = (order_id = global.order_id) => {
        if (order_id) handleBookingExpired(order_id)

        // return false;
        setTimeLeft(0)
        setTimePassed(true);
        dispatch({type: 'ORDER-ID', payload: ''})
        dispatch({type: 'ORDER-TIME', payload: ''})
        dispatch({type: 'CHECKOUT-TIME', payload: ''})
        dispatch({type: 'CART-ITEMS-COUNT', payload: 0})
        dispatch({type: 'SHIPPING-ADDRESS', payload: false})
    }

    if (timePassed || timeLeft <= 1) return null;

    return (
        <div>
            {timePassed ?
                (
                    <span>{formatTime(timeLeft)} Time Passed.</span>
                ) :
                (
                    <span>
                    <span>{formatTime(timeLeft)} To Checkout </span>
                        {(timeLeft > 10 && timeLeft <= 600) &&
                            <div className='link d-inline-block'
                                 onClick={(e) => handleExtendTime(e)}>(Extend)</div>
                        }
                </span>
                )}
        </div>
    )
}