import React, {useState, useEffect, useContext} from 'react';
import {Container} from 'react-bootstrap';
import Api from "../_services/api_service";
import {GlobalContext} from "../_services/global-context";
import {ArrowClockwise} from "react-bootstrap-icons"
import Services from "../_services/services";
import logo_image from "../../images/playtopia-logo-1.png";
import URL from "../_services/routes";

const ResetPassword = () => {
    const [global, dispatch] = useContext(GlobalContext);
    const [processing, setProcessing] = useState(false);
    const [error_message, setErrorMessage] = useState('');
    const [reset, setReset] = useState({});
    const [access, setAccess] = useState(true);
    const [api_url, setApiUrl] = useState('');
    const [baseURL, setBaseURL] = useState(URL.baseUrl());
    const [companyId, setCompanyId] = useState(null);
    const [codeSend, setCodeSend] = useState(false);
    const [codeVerified, setCodeVerified] = useState(false);

    useEffect(() => {
    }, [baseURL]);

    const handleChange = (name, value) => {
        reset[name] = value;
        setReset(reset);
    }

    const handleSendOTPCode = (e) => {
        e.preventDefault();

        setProcessing(true)
        reset['company_id'] = global.pcl.company_id;
        reset['base_url'] = baseURL;
        Api.post('auth/forgot-password', reset, (res) => {
            const data = res.data.data;
            setCodeSend(true)
            setProcessing(false)
            setErrorMessage('');
            dispatch({type: "SHOW-SUCCESS", payload: 'OTP sent to your email successfully.'});
        }, (errors) => {
            setCodeSend(false)
            setProcessing(false)
            setErrorMessage(JSON.stringify(errors));
            dispatch({type: "SHOW-ERROR", payload: JSON.stringify(errors)});
        });
    }

    const handleVerifyCode = (e) => {
        e.preventDefault();

        setProcessing(true)
        reset['base_url'] = baseURL;
        Api.post('auth/verify-password-reset-code', reset, (res) => {
            const data = res.data.data;
            setCodeVerified(true);
            setProcessing(false);
            setErrorMessage('');
            dispatch({type: "SHOW-SUCCESS", payload: res.data.message});
        }, (error) => {
            setProcessing(false)
            setErrorMessage(JSON.stringify(error));
            dispatch({type: "SHOW-ERROR", payload: JSON.stringify(error)});
        });
    }

    const handleResetPassword = (e) => {
        e.preventDefault();

        setProcessing(true)
        reset['base_url'] = baseURL;
        reset['request_type'] = 'client';
        Api.post('auth/reset-password', reset, (res) => {
            const data = res.data.data;
            dispatch({type: "SHOW-SUCCESS", payload: res.data.message});
            setTimeout(() => {
                Services.redirectTo('/login')
            }, 1500)
        }, (error) => {
            setProcessing(false)
            setErrorMessage(JSON.stringify(error));
            dispatch({type: "SHOW-ERROR", payload: JSON.stringify(error)});
        });
    }

    return (
        <div className=''>
            <Container>
                <div className="auth-inner mt-5">

                    <div style={{width: '133px'}} className='mb-2 m-auto'>
                        <img style={{width: '100%'}} src={global.pcl.dark_logo || logo_image} alt='Playtopia'/>
                    </div>
                    {error_message !== '' && (
                        <div className="text-center fs-17x mb-3">
                            <span style={{color: 'red'}}>{error_message}</span>
                        </div>
                    )}


                    {!(codeVerified) &&
                        <form onSubmit={(!codeSend) ? handleSendOTPCode : handleVerifyCode}>
                            {(!codeSend) ?
                                <>
                                <div className='text-muted fs-20x mb-2'>Enter your email to reset password</div>

                                <div style={{textAlign: 'left'}}>
                                    <div className="form-group">
                                        <label className=''>Registered Email</label>
                                        <input type="email" className="form-control" placeholder="abc@gmail.com"
                                               name="email" defaultValue={reset.email}
                                               onChange={(e) => handleChange('email', e.target.value)}
                                               disabled={!access}/>
                                    </div>
                                    <br/>
                                    <div className="form-group text-end">
                                        <button type="submit" className="btn btn-success btn-sm"
                                                onClick={handleSendOTPCode}>Send Verification Code
                                            {processing && <span>&nbsp;processing...</span>}
                                        </button>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <div className='text-muted fs-20x mb-2'>Please enter verification code.</div>

                                <div className='d-flex gap-1 align-items-center'>
                                    <div className="form-group flex-1">
                                        <label className=''>Confirm Email</label>
                                        <input type="email" className="form-control" placeholder="abc@gmail.com"
                                               name="email" defaultValue={reset.email}
                                               onChange={(e) => handleChange('email', e.target.value)}
                                               disabled={!access}/>
                                    </div>

                                    <div>
                                        <button type="button" style={{marginTop: '22px'}}
                                                data-tooltip-id='tooltip'
                                                data-tooltip-content='Resend Code'
                                                className="btn btn-primary btn-sm"
                                                onClick={handleSendOTPCode}>
                                            {(processing) ? <span>&nbsp;Sen...</span> :
                                                <ArrowClockwise size={17}/>}
                                        </button>
                                    </div>
                                </div>

                                <div style={{textAlign: 'left'}}>
                                    <div className="form-group mt-2">
                                        <label className=''>Enter verification code</label>
                                        <input type="text" className="form-control"
                                               placeholder="Verification code"
                                               defaultValue={reset.otp}
                                               onChange={(e) => handleChange('otp', e.target.value)}
                                        />
                                        <small className='fs-13x text-warning'>Verification code will be expired
                                            after 5 minutes</small>
                                    </div>

                                    <br/>
                                    <div className="form-group text-end">
                                        <button type="submit" className="btn btn-success btn-sm"
                                                onClick={handleVerifyCode}>Verify
                                            {processing && <span>&nbsp;...</span>}
                                        </button>
                                    </div>
                                </div>
                            </>
                        }
                    </form>
                    }

                    {(codeVerified && codeSend) &&
                    <form onSubmit={handleResetPassword}>
                        <div className='text-muted fs-17x mb-2'>
                            Password requires at least 6 characters, one lowercase letter,
                            one uppercase letter, one digit, and one special character.
                        </div>

                        <div style={{textAlign: 'left'}}>
                            <div className="form-group mt-2">
                                <label className=''>New Password</label>
                                <input type="password" className="form-control" placeholder="---"
                                       defaultValue={reset.password}
                                       onChange={(e) => handleChange('password', e.target.value)}
                                />
                            </div>

                            <div className="form-group mt-2">
                                <label className=''>Re-enter Password</label>
                                <input type="password" className="form-control" placeholder="---"
                                       defaultValue={reset.password_confirmation}
                                       onChange={(e) => handleChange('password_confirmation', e.target.value)}
                                />
                            </div>

                            <br/>
                            <div className="form-group text-end">
                                <button type="submit" className="btn btn-success btn-sm"
                                        onClick={handleResetPassword}>Reset Password
                                    {processing && <span>&nbsp;...</span>}
                                </button>
                            </div>
                        </div>
                    </form>
                    }

                </div>
                <br/>
            </Container>
        </div>
    );
};

export default ResetPassword;
