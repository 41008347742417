import React, {useContext, useEffect} from "react";
import {GlobalContext} from "../_services/global-context";
import {Col} from "react-bootstrap";
import Cart from './cart'

export default function QuickCart() {
    const [global, dispatch] = useContext(GlobalContext);

    // Effects
    useEffect(() => {
    }, [global.order_id])

    // console.log('cartDetails', cartDetails)
    return (
        <Col className='col-3 quick-cart'>
            <Cart viewType={'short-details'}/>
        </Col>
    )
}