import React, {useContext, useEffect, useState} from "react";
import {Container, Dropdown, Nav, Navbar} from 'react-bootstrap';
import {PhoneVibrate, Person, Cart4, PersonCircle, BoxArrowInRight, PersonBadge, HouseFill, ViewList, BorderStyle, JournalPlus} from "react-bootstrap-icons";
import {GlobalContext} from "../_services/global-context";
import logo_image from "../../images/playtopia-logo-1.png";
import CheckoutTimer from "../checkout_timer";
import MobileCart from "./mobile_cart";
import TopBar from "./topbar";

function TopNavManu() {
    const [global, dispatch] = useContext(GlobalContext)
    const [isMobile, setIsMobile] = useState(window.innerWidth < 567);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        }

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }

    }, []);


    const handleLoginClick = () => {
        dispatch({type: 'RETURNING-URLS', payload: []})
        window.location.assign('/login');
    }

    //console.log('isScroll: ', isScroll)
    return (
        <>
            <div className='sticky'>
                <TopBar/>

                <Navbar expand="lg" className={`bg-body-tertiary pt-1 pb-1`}>
                    <Container>
                        <Navbar.Brand href="/">
                            <img src={global.pcl.dark_logo || logo_image} alt='Playtopia' style={{width: '73px', maxHeight: '59px'}}/>
                        </Navbar.Brand>

                        <Navbar.Collapse id="navbarScroll">
                            {/*style={{maxHeight: '100px'}}*/}
                            <Nav className="me-auto my-2 my-lg-0" navbarScroll>
                                <Nav.Link href="/" className='d-flex align-items-center gap-1'><HouseFill className='text-green'/> Home</Nav.Link>

                                {(global.token && global.user && global.user.id)&&
                                    <>
                                        {/*<Nav.Link href="/waiver" className='d-flex align-items-center gap-1'><XDiamondFill className='text-green'/> Kiosk Waiver</Nav.Link>*/}
                                        <Nav.Link href="/my-orders" className='d-flex align-items-center gap-1'><ViewList className='text-green fw-bold fs-20x'/> My Orders</Nav.Link>
                                        <Nav.Link href="/my-waivers?rd=my-waivers" className='d-flex align-items-center gap-1'><BorderStyle className='text-green fw-bold fs-20x'/> My Waivers</Nav.Link>
                                    </>
                                }

                                <Nav.Link href="#" className='d-flex align-items-center gap-1' onClick={(e) => {
                                    e.preventDefault()
                                    dispatch({type: 'RETURNING-URLS', payload: []})
                                    window.location.assign('/waiver');
                                }}>
                                    <JournalPlus className='text-green fw-bold fs-20x'/> Sign Waiver
                                </Nav.Link>
                            </Nav>

                            {(!isMobile)&&
                                <Nav className="me-auto" style={{maxHeight: '100px'}} navbarScroll>
                                    <Nav.Link className='fw-bold fs-18x' onClick={(e) => e.preventDefault()}>
                                        <CheckoutTimer/>
                                    </Nav.Link>
                                </Nav>
                            }

                            <Nav className="me-2 my-lg-0">
                                <Nav.Link href="#action1"><span className='fs-20x text-green'><PhoneVibrate/></span> Contact Us</Nav.Link>
                                {/*<Nav.Link href="/login"><span className='text-green'><Person /></span> Login</Nav.Link>*/}

                                {(!global.token) &&
                                    <Nav.Link href="#" onClick={() => handleLoginClick()}><span className='fs-20x text-green'><Person /></span> Login</Nav.Link>
                                }
                            </Nav>
                        </Navbar.Collapse>

                        <Nav.Link href="/cart" className='pos position-relative ms-auto me-3' style={{marginTop: '-6px'}}>
                            <div className='fs-37x text-warning'><Cart4/></div>
                            <div className='cart-counter fw-bold'>{global.cart_items_count}</div>
                        </Nav.Link>

                        {(global.token && global.user && global.user.id)&&
                            <Dropdown align="end" className='me-2'>
                                <Dropdown.Toggle size={'sm'} variant="light"><PersonCircle size={37} className='text-success'/></Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {/*<Dropdown.Item href="/my-account"><span className='text-green'><PersonBadge /></span> My Account</Dropdown.Item>*/}
                                    <Dropdown.Item href="/my-profile"><span className='text-green'><PersonBadge /></span> My Profile</Dropdown.Item>
                                    <Dropdown.Divider/>
                                    <Dropdown.Item href="/logout"><span className='text-green'><BoxArrowInRight /></span> Logout</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        }

                        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                    </Container>
                </Navbar>
            </div>

            {/*
            <Dropdown align="end" className='ms-2'>
                        <Dropdown.Toggle variant="light" id="dropdown-basic">
                            <PersonCircle size={30}/>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item href="#/profile">Account</Dropdown.Item>
                            <Dropdown.Divider/>
                            <Dropdown.Item href="/logout">Logout</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
            */}

            {/* Cart and checkout time for mobile view */}
            {(isMobile && global.checkout_time) &&
                <MobileCart isMobile={isMobile}/>
            }
        </>
    );
}

export default TopNavManu;