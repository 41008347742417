import React, {useEffect, useState} from "react";
import {Carousel} from "react-bootstrap";
import pd_image from "../images/product-dummy-image-1.jpg";

export default function ImagesSlider({imagesData}) {
    const [activeIndex, setActiveIndex] = useState(0);
    const [images, setImages] = useState(imagesData);

    useEffect(() => {

    }, []);

    // Placeholder secondary images
    if (images.length === 0) {
        setImages([pd_image, pd_image, pd_image])
    }

    return (
        <div className="p-1">
            {/*  ----------------------------------  */}
            <div className="slider-container">
                <Carousel activeIndex={activeIndex} onSelect={(selectedIndex) => setActiveIndex(selectedIndex)}
                          interval={3000} pause="hover">
                    {images.map((image, index) => (
                        <Carousel.Item key={index}>
                            <img className="d-block w-100 main-img" src={image?.url} alt={`Slide ${index + 1}`}/>
                            {/*
                                <Carousel.Caption>
                                    <h3>Slide {index + 1}</h3>
                                    <p>Description for slide {index + 1}.</p>
                                </Carousel.Caption>
                            */}
                        </Carousel.Item>
                    ))}
                </Carousel>

                {/* Thumbnail Navigation */}
                <div className="thumbnail-container">
                    {images.map((image, index) => (
                        <img key={index} className={`thumbnail ${activeIndex === index ? "active-thumbnail" : ""}`}
                             src={image?.url} alt={`Thumbnail ${index + 1}`}
                            onClick={() => setActiveIndex(index)} // Change active slide on click
                        />
                    ))}
                </div>
            </div>
            {/*  ----------------------------------  */}
        </div>
    );
}
