import React, {useEffect, useState, useContext} from "react";
import {Container, Row, Col, Button, Carousel} from "react-bootstrap";
import {GlobalContext} from "../_services/global-context";
import Api from "../_services/api_service";
import {useNavigate} from 'react-router-dom';
import pd_image from "../../images/product-dummy-image-1.jpg"; // Import placeholder image

export default function ItemDetails({path, setQuantity, handleCheckProperties}) {
    const [global, dispatch] = useContext(GlobalContext);
    const [itemDetails, setItemDetails] = useState(null);
    const [category, setCategory] = useState(null);
    const [loading, setLoading] = useState(true);
    const [activeIndex, setActiveIndex] = useState(0);

    const navigate = useNavigate();

    useEffect(() => {
        // Fetch item details from API
        fetchItemDetails();
    }, [path]);

  const fetchItemDetails = () => {
    const pclId = global.pcl.id; // Use pcl_id from global context
    Api.get(
        `client/categories?pcl_id=${pclId}&category=${path}`,
        (response) => {
          const {items} = response.data || {};
          if (items && items.length > 0) {
            setCategory(response.data);
            setItemDetails(items[0]); // Set the first item from the response
          } else {
            setItemDetails(null);
          }
          setLoading(false);
        },
        (error) => {
          console.error("Failed to fetch item details:", error);
          setLoading(false);
        }
    );
  };

    const addToCart = () => {
        navigate(`/cart/add?category_id=${category.id}&item_id=${itemDetails.id}&quantity=1`);
    };

    if (loading) {
        return (
            <Container className="py-4 text-center">
                <h4>Loading item details...</h4>
            </Container>
        );
    }

    if (!itemDetails) {
        return (
            <Container className="py-4 text-center">
                <h4>Item not found</h4>
            </Container>
        );
    }

    // Placeholder secondary images
    const secondaryImages = [pd_image, pd_image, pd_image];

    let image_url = null;
    if (itemDetails?.images && itemDetails.images.length > 0) {
        image_url = itemDetails.images[0];
    }
    image_url = image_url?.url?.trim() || pd_image;

    return (
        <Container className="py-4">
            <Row>
                {/* Desktop Images Section */}
                <Col md={6} className="">
                    {/*  ----------------------------------  */}
                    <div className="slider-container">
                        <Carousel activeIndex={activeIndex} onSelect={(selectedIndex) => setActiveIndex(selectedIndex)} interval={3000} pause="hover">
                            {itemDetails?.images?.map((image, index) => (
                                <Carousel.Item key={index}>
                                    <img
                                        className="d-block w-100 main-img"
                                        src={image?.url}
                                        alt={`Slide ${index + 1}`}
                                    />
                                    {/*<Carousel.Caption>
                                        <h3>Slide {index + 1}</h3>
                                        <p>Description for slide {index + 1}.</p>
                                    </Carousel.Caption>*/}
                                </Carousel.Item>
                            ))}
                        </Carousel>

                        {/* Thumbnail Navigation */}
                        <div className="thumbnail-container">
                            {itemDetails?.images?.map((image, index) => (
                                <img
                                    key={index}
                                    src={image?.url}
                                    alt={`Thumbnail ${index + 1}`}
                                    className={`thumbnail ${activeIndex === index ? "active-thumbnail" : ""}`}
                                    onClick={() => setActiveIndex(index)} // Change active slide on click
                                />
                            ))}
                        </div>
                    </div>

                    {/*  ----------------------------------  */}
                </Col>

                {/* Text Section */}
                <Col md={6}>
                    <div className='mt-4 text-center border-bottom mb-3'>
                        <h2 className="mb-2 fw-bold">{itemDetails.name}</h2>
                        <h4 className="text-success mb-2 fw-bold">
                            Price: ${itemDetails.prices?.applied_price.toFixed(2)}
                        </h4>
                    </div>
                    <strong>Description:</strong>
                    <p className="mb-4">{itemDetails.description || "No description available."}</p>

                    <strong>Features:</strong>
                    <p className="mb-4" dangerouslySetInnerHTML={{__html: itemDetails.features || ""}}></p>

                    <div className='d-flex justify-content-center gap-3'>
                        <div>
                            <select className='form-select form-select-md mb-2 d-inline rounded-pill'
                                    style={{maxWidth: '92px'}}
                                    onChange={(e) => setQuantity(e.target.value)}>
                                {/*<option selected value="1">1</option>*/}
                                {(() => {
                                    let elements = [];
                                    for (let i = 1; i <= 20; i++) {
                                        if (i < itemDetails.min_qty_per_order || itemDetails.sell_as_single_item_per_line > i) continue;
                                        if (i > itemDetails.max_qty_per_order || i > itemDetails.max_sellable_qty) {
                                            break;
                                        }

                                        elements.push(<option key={i} value={i}>{i}</option>);

                                        if ((itemDetails.sell_as_single_item_per_line || itemDetails.sell_as_single_item_per_order)) {
                                            break;
                                        }
                                    }
                                    return elements;
                                })()}
                            </select>
                        </div>

                        <Button variant="success" className="mb-3 rounded-pill"
                                onClick={() => handleCheckProperties(category.id, itemDetails)}>Add to Cart</Button>
                    </div>

                    <div>
                        <h5>Terms & Conditions</h5>
                        <p>No specific terms and conditions provided.</p>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}
