import React, {useState, useEffect, useContext} from 'react';
import {Button, Col, Container, Form, Row} from 'react-bootstrap';
import Api from "./_services/api_service";
import {GlobalContext} from "./_services/global-context";
import Services from "./_services/services";
import Select from "react-select";

const ShippingAddress = () => {
    const [global, dispatch] = useContext(GlobalContext);
    const [processing, setProcessing] = useState(false);
    const [errors, setErrors] = useState({});
    const [shippingErrors, setShippingErrors] = useState({});
    const [error_message, setErrorMessage] = useState('');

    const [countries, setCountries] = useState([])
    const [states, setStates] = useState([])
    const [selectedState, setSelectedState] = useState({})
    const [selectedCountry, setSelectedCountry] = useState({})
    const [shippingSelectedState, setShippingSelectedState] = useState({})
    const [shippingSelectedCountry, setShippingSelectedCountry] = useState({})

    const [shippingAddress, setShippingAddress] = useState({})
    const [isShippingAddress, setIsShippingAddress] = useState(false)
    const [shippingType, setShippingType] = useState('');


    const user_default = {id: '', country_id: '', state_id: '', city: '', postal_code: '',
        address1: '', address2: '', shipping_address_same_as_billing: true
    }
    const [user, setUser] = useState(user_default);

    useEffect(() => {
        loadCountries()
        if (global.order_id) {
            handleLoadCartData()
        }

    }, []);

    useEffect(() => {
        if (countries.length > 0) {
            handleLoadShippingAddress()
        }

    }, [countries]);

    const handleLoadCartData = () => {
        const params = {
            pcl_id: global.pcl.id,
        }

        const qryParams = new URLSearchParams(params).toString()
        Api.get(`client/orders/${global.order_id}?` + qryParams, (res) => {
            const data = res.data.data
            data.cart_details.map(item => {
                if (item.shippable_item) {
                    setIsShippingAddress(true);
                }
            })
        }, (errors) => {
            //dispatch({type: "SHOW-ERROR", payload: JSON.stringify(errors)})
        })
    }

    // Get all countries list
    const loadCountries = () => {
        Api.get(`admin/countries?pcl_id=${global.pcl.id}`, res => {
            const countries_arr = res.data.data;
            let options = []
            countries_arr.forEach(country => {
                let option = {value: country.id, label: country.name}
                options.push(option)
            })
            setCountries([...options]);
        }, error => {
            dispatch({type: "SHOW-ERROR", payload: error.message});
        });
    }

    // Load states/provinces list on country select
    function handleCountryChange(f_name, country_id, state_id = null, user_data = {}) {
        if (f_name === 'state_id') {
            setSelectedState({})
        }else {
            setShippingSelectedState({})
        }

        Api.get(`admin/states/${country_id}?pcl_id=${global.pcl.id}`, res => {
            const states_arr = res.data.data;
            let options = []
            states_arr.forEach(state => {
                let option = {value: state.id, label: state.name}

                if (state_id && state_id == state.id) {
                    f_name === 'state_id' ? setSelectedState({ ...option}) : setShippingSelectedState({ ...option});
                    if (user_data.id && user_data.id != ''){
                        user_data[f_name] = state.id
                        setUser({...user_data})
                    }else {
                        user[f_name] = state.id
                        setUser({...user})
                    }
                }

                options.push(option)
            })

            setStates([...options]);
        }, error => {
            dispatch({type: "SHOW-ERROR", payload: error.message});
        });
    }

    const handleChange = (name, val) => {
        if (name === 'country_id' || name === 'shipping_country_id') {
            name === 'country_id' ? setSelectedCountry({ ...val}) : setShippingSelectedCountry({ ...val});
            val = val.value

            const state_name = (name === 'country_id') ? 'state_id' : 'shipping_state_id'
            handleCountryChange(state_name, val)
        }else if (name === 'state_id' || name === 'shipping_state_id') {
            name === 'state_id' ? setSelectedState({ ...val}) : setShippingSelectedState({ ...val});
            val = val.value
        }

        user[name] = val;
        setUser({...user});
    }

    const handleChangeShippingAddress = (name, val) => {
        if (name === 'country_id') {
            setShippingSelectedCountry({ ...val});
            val = val.value

            const state_name = 'shipping_state_id'
            handleCountryChange(state_name, val)
        }else if (name === 'state_id') {
            setShippingSelectedState({ ...val});
            val = val.value
        }else if (name === 'shipping_type') {
            setShippingType(val)
            if (val === 'delivery' && shippingAddress['shipping_address_same_as_billing']) {
                handleSetShippingSameAsBilling(true);
            }else {
                handleSetShippingSameAsBilling(false);
            }
        }

        if (name === 'shipping_address_same_as_billing') {
            if (val) {
                handleSetShippingSameAsBilling(true);
            }else {
                handleSetShippingSameAsBilling(false);
            }
        }

        shippingAddress[name] = val;
        setShippingAddress({...shippingAddress});
    }

    const handleSetShippingSameAsBilling = (set_billing = false) => {
        if (set_billing) {
            shippingAddress['city'] = user['city'];
            shippingAddress['postal_code'] = user['postal_code'];
            shippingAddress['address1'] = user['address1'];
            shippingAddress['address2'] = user['address2'];

            const sh_country = countries.find(c => c.value == user['country_id'])
            const sh_state = states.find(c => c.value == user['state_id'])

            shippingAddress['country_id'] = (sh_country) ? user['country_id'] : '';
            shippingAddress['state_id'] = (sh_state) ? user['state_id'] : '';

            setShippingSelectedCountry(sh_country || {})
            setShippingSelectedState(sh_state || {})
        }else {
            shippingAddress['country_id'] = '';
            shippingAddress['state_id'] = '';
            shippingAddress['city'] = '';
            shippingAddress['postal_code'] = '';
            shippingAddress['address1'] = '';
            shippingAddress['address2'] = '';
            setShippingSelectedCountry({})
            setShippingSelectedState({})
        }

        setShippingAddress({...shippingAddress});
    }

    const handleLoadShippingAddress = () => {
        const params = `?pcl_id=${global.pcl.id}`
        Api.get(`client/users/${global.user.id}` + params, (res) => {
            const data = res.data.data;
            const country_id = data.country_id || global.location.country_id
            setUser({...data})

            // Billing address
            for (let country of countries) {
                if (country.value === country_id) {
                    setSelectedCountry({...country})
                    data['country_id'] = country_id;

                    const state_id = data.state_id || global.location.state_id
                    handleCountryChange('state_id', country.value, state_id, data)
                    break;
                }
            }

            // Shipping address
            const s_country_id = data.shipping_country_id;
            for (let country of countries) {
                if ((s_country_id && s_country_id == country.value) || country.value === country_id) {
                    setShippingSelectedCountry({...country})
                    data['shipping_country_id'] = s_country_id || country_id;

                    const state_id = data.shipping_state_id || global.location.state_id
                    handleCountryChange('shipping_state_id', country.value, state_id, data)
                    break;
                }
            }



        }, (error) => {
            console.log('error: ', error)
            setErrors(error);
            setErrorMessage(Services.getErrorList(error))
            // dispatch({type: "SHOW-ERROR", payload: Services.getErrorList(error)})
        });
    }

    const handleSaveAddresses = (e) => {
        e.preventDefault();
        handleSaveBillingAddress()
    }

    const handleSaveBillingAddress = () => {
        user['pcl_id'] = global.pcl.id;
        if (global.order_id) {
            user['order_id'] = global.order_id;
        }

        setProcessing(true)
        Api.put(`client/users/${user.id}`, user, (res) => {
            const data = res.data.data;

            if (isShippingAddress) {
                handleSaveShippingAddress();
            }else {
                dispatch({type: 'SHIPPING-ADDRESS', payload: true});
                dispatch({type: "SHOW-SUCCESS", payload: 'Shipping address saved successfully!'});

                setTimeout(() => {
                    let returning_url = Services.getReturningUrl();
                    window.location.assign(returning_url || '/');
                }, 700);
            }


        }, (error) => {
            setProcessing(false);
            setErrors(error);
            dispatch({type: "SHOW-ERROR", payload: Services.getErrorList(error)})
        });
    }

    const handleSaveShippingAddress = () => {
        shippingAddress['pcl_id'] = global.pcl.id;
        if (global.order_id) {
            shippingAddress['entity_id'] = global.order_id;
            shippingAddress['entity_type'] = 'orders';
        }

        setProcessing(true)
        Api.post(`client/addresses`, shippingAddress, (res) => {
            const data = res.data.data;
            dispatch({type: 'SHIPPING-ADDRESS', payload: true})
            dispatch({type: "SHOW-SUCCESS", payload: 'Addresses saved successfully.'})

            setTimeout(() => {
               let returning_url = Services.getReturningUrl();
               window.location.assign(returning_url || '/');
            }, 700)

        }, (error) => {
            setProcessing(false);
            setShippingErrors(error);
            dispatch({type: "SHOW-ERROR", payload: Services.getErrorList(error)})
        });
    }


    // React select style
    const targetHeight = 38
    const selectStyles = {
        control: (base) => ({
            ...base,
            minHeight: 'initial',
        }),
        valueContainer: (base) => ({
            ...base,
            height: `${targetHeight - 1 - 1}px`,
            padding: '0 8px',
        }),
        clearIndicator: (base) => ({
            ...base,
            padding: `${(targetHeight - 20 - 1 - 1) / 2}px`,
        }),
        dropdownIndicator: (base) => ({
            ...base,
            padding: `${(targetHeight - 20 - 1 - 1) / 2}px`,
        }),
    }

    console.log('shippingAddress: ', shippingAddress)
    return (
        <>
            <Container>
                <div className="auth-wrapper">
                    <Form onSubmit={handleSaveAddresses} autoComplete="off">

                        <div className='fw-bold text-center fs-21x mt-3'>Enter your billing address!</div>
                        {error_message !== '' && (
                            <div className="text-center fs-17x mb-3">
                                <span style={{color: 'red'}}>{error_message}</span>
                            </div>
                        )}

                        <Row className='mt-3'>
                            <Col md={4}>
                                <Form.Group className="mb-3" controlId="country_id">
                                    <Form.Label className=''>Country</Form.Label>
                                    <Select styles={selectStyles}
                                            value={selectedCountry}
                                            options={countries}
                                            className={(errors.country_id) && 'is-invalid'}
                                            onChange={(e) => handleChange('country_id', e)}/>
                                    <div className="invalid-feedback">{errors.country_id}</div>
                                </Form.Group>
                            </Col>

                            <Col md={4}>
                                <Form.Group className="mb-3" controlId="state_id">
                                    <Form.Label className=''>State</Form.Label>
                                    <Select styles={selectStyles} placeholder='Select state'
                                            options={states} value={selectedState}
                                            className={(errors.state_id) && 'is-invalid'}
                                            onChange={(e) => handleChange('state_id', e)}/>
                                    <div className="invalid-feedback">{errors.state_id}</div>
                                </Form.Group>
                            </Col>

                            <Col md={4}>
                                <Form.Group className="mb-3" controlId="city">
                                    <Form.Label>City</Form.Label>
                                    <Form.Control size='md' type="text" className={(errors.city) && 'is-invalid'}
                                                  placeholder='city'
                                                  value={user.city}
                                                  onChange={(e) => handleChange('city', e.target.value)}/>
                                    <Form.Text className="text-danger">{errors.city}</Form.Text>
                                </Form.Group>
                            </Col>


                            <Col md={4}>
                                <Form.Group className="mb-3" controlId="postal_code">
                                    <Form.Label>Postal Code</Form.Label>
                                    <Form.Control size='md' type="text"
                                                  className={(errors.postal_code) && 'is-invalid'}
                                                  placeholder="postal code"
                                                  value={user.postal_code}
                                                  onChange={(e) => handleChange('postal_code', e.target.value)}/>
                                    <Form.Text className="text-danger">{errors.postal_code}</Form.Text>
                                </Form.Group>
                            </Col>

                            <Col md={4}>
                                <Form.Group className="mb-3" controlId="address1">
                                    <Form.Label>Address 1 <small className='text-muted'>(Street address or P.O
                                        Box)</small></Form.Label>
                                    <Form.Control size='md' type='text'
                                                  placeholder="Street address or P.O Box"
                                                  className={(errors.address1) && 'is-invalid'}
                                                  value={user.address1}
                                                  onChange={(e) => handleChange('address1', e.target.value)}/>
                                    <div className="invalid-feedback">{errors.address1}</div>
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="mb-3" controlId="address2">
                                    <Form.Label>Address 2 <small className='text-muted'>(Apt., Unit, Building,
                                        Suite)</small></Form.Label>
                                    <Form.Control size='md' type='text'
                                                  placeholder="Apt., Unit, Building, Suite"
                                                  className={(errors.address2) && 'is-invalid'}
                                                  value={user.address2}
                                                  onChange={(e) => handleChange('address2', e.target.value)}/>
                                    <div className="invalid-feedback">{errors.address2}</div>
                                </Form.Group>
                            </Col>

                        </Row>


                        {(isShippingAddress)&&
                            <>
                                <div className='fw-bold text-center fs-21x mt-3 border-bottom'>Select your Delivery Method!</div>
                                <div className='mt-4 mb-4'>
                                    <Form.Group className='text-center mb-3'>
                                        <Form.Check inline type="radio" id="digital" className='radio-lg me-3' label="Digital (Email)"
                                                    value="digital"
                                                    checked={shippingType === 'digital'}
                                                    onChange={(e) => handleChangeShippingAddress('shipping_type', e.target.value)}
                                        />
                                        <Form.Check inline type="radio" id="pickup" className='radio-lg me-3' label="Physical Pickup"
                                                    value="pickup"
                                                    checked={shippingType === 'pickup'}
                                                    onChange={(e) => handleChangeShippingAddress('shipping_type', e.target.value)}
                                        />
                                        <Form.Check inline type="radio" id="delivery" className='radio-lg' label="Physical Delivery"
                                                    value="delivery"
                                                    checked={shippingType === 'delivery'}
                                                    onChange={(e) => handleChangeShippingAddress('shipping_type', e.target.value)}
                                        />
                                    </Form.Group>
                                </div>

                                {(shippingType === 'digital') &&
                                    <Row className='mt-3'>
                                        <Col md={4}>
                                            <Form.Group className="mb-3" controlId="first_name">
                                                <Form.Label>Receiver First Name</Form.Label>
                                                <Form.Control size='md' type="text"
                                                              className={(shippingErrors.first_name) && 'is-invalid'}
                                                              placeholder="Enter name"
                                                              defaultValue={shippingAddress.first_name}
                                                              onChange={(e) => handleChangeShippingAddress('first_name', e.target.value)}/>
                                                <Form.Text
                                                    className="text-danger">{shippingErrors.first_name}</Form.Text>
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group className="mb-3" controlId="last_name">
                                                <Form.Label>Last Name</Form.Label>
                                                <Form.Control size='md' type="text"
                                                              className={(shippingErrors.last_name) && 'is-invalid'}
                                                              placeholder="Enter name"
                                                              defaultValue={shippingAddress.last_name}
                                                              onChange={(e) => handleChangeShippingAddress('last_name', e.target.value)}/>
                                                <Form.Text
                                                    className="text-danger">{shippingErrors.last_name}</Form.Text>
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group className="mb-3" controlId="email">
                                                <Form.Label>Receiver Email</Form.Label>
                                                <Form.Control size='md' type="text"
                                                              className={(shippingErrors.email) && 'is-invalid'}
                                                              placeholder="Enter email"
                                                              defaultValue={shippingAddress.email}
                                                              onChange={(e) => handleChangeShippingAddress('email', e.target.value)}/>
                                                <Form.Text
                                                    className="text-danger">{shippingErrors.email}</Form.Text>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                }

                                {(shippingType === 'delivery') &&
                                    <>
                                        <div className='mt-2 mb-3 d-flex justify-content-center'>
                                            <Form.Check size='sm' type="checkbox" name="shippable_item" className='radio-lg'
                                                        id="shipping_address_same_as_billing"
                                                        label="Shipping address same as billing address"
                                                        checked={shippingAddress.shipping_address_same_as_billing}
                                                        onChange={(e) => handleChangeShippingAddress('shipping_address_same_as_billing', e.target.checked)}
                                            />
                                        </div>

                                        {(!user.shipping_address_same_as_billing || 1 === 1) &&
                                            <Row className='mt-3'>
                                                <Col md={4}>
                                                    <Form.Group className="mb-3" controlId="country_id">
                                                        <Form.Label className=''>Country</Form.Label>
                                                        <Select styles={selectStyles} className={(shippingErrors.country_id) && 'is-invalid'}
                                                                value={shippingSelectedCountry}
                                                                options={countries}
                                                                onChange={(e) => handleChangeShippingAddress('country_id', e)}/>
                                                        <div className="invalid-feedback">{shippingErrors.country_id}</div>
                                                    </Form.Group>
                                                </Col>

                                                <Col md={4}>
                                                    <Form.Group className="mb-3" controlId="state_id">
                                                        <Form.Label className=''>State</Form.Label>
                                                        <Select styles={selectStyles} className={(shippingErrors.state_id) && 'is-invalid'}
                                                                value={shippingSelectedState}
                                                                options={states}
                                                                onChange={(e) => handleChangeShippingAddress('state_id', e)}/>
                                                        <div className="invalid-feedback">{shippingErrors.state_id}</div>
                                                    </Form.Group>
                                                </Col>

                                                <Col md={4}>
                                                    <Form.Group className="mb-3" controlId="city">
                                                        <Form.Label>City</Form.Label>
                                                        <Form.Control size='md' type="text" placeholder='city'
                                                                      className={(shippingErrors.city) && 'is-invalid'}
                                                                      value={shippingAddress.city}
                                                                      onChange={(e) => handleChangeShippingAddress('city', e.target.value)}/>
                                                        <Form.Text className="text-danger">{shippingErrors.city}</Form.Text>
                                                    </Form.Group>
                                                </Col>

                                                <Col md={4}>
                                                    <Form.Group className="mb-3" controlId="postal_code">
                                                        <Form.Label>Postal Code</Form.Label>
                                                        <Form.Control size='md' type="text" placeholder="postal code"
                                                                      className={(shippingErrors.postal_code) && 'is-invalid'}
                                                                      value={shippingAddress.postal_code}
                                                                      onChange={(e) => handleChangeShippingAddress('postal_code', e.target.value)}/>
                                                        <Form.Text
                                                            className="text-danger">{shippingErrors.postal_code}</Form.Text>
                                                    </Form.Group>
                                                </Col>

                                                <Col md={4}>
                                                    <Form.Group className="mb-3" controlId="address1">
                                                        <Form.Label>Address 1 <small className='text-muted'>
                                                            (Street address or P.O Box)</small></Form.Label>
                                                        <Form.Control size='md' type='text'
                                                                      placeholder="Street address or P.O Box"
                                                                      className={(shippingErrors.address1) && 'is-invalid'}
                                                                      value={shippingAddress.address1}
                                                                      onChange={(e) => handleChangeShippingAddress('address1', e.target.value)}/>
                                                        <div className="invalid-feedback">{shippingErrors.address1}</div>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={4}>
                                                    <Form.Group className="mb-3" controlId="address2">
                                                        <Form.Label>Address 2 <small className='text-muted'>
                                                            (Apt., Unit, Building, Suite)</small></Form.Label>
                                                        <Form.Control size='md' type='text'
                                                                      placeholder="Apt., Unit, Building, Suite"
                                                                      className={(shippingErrors.address2) && 'is-invalid'}
                                                                      value={shippingAddress.address2}
                                                                      onChange={(e) => handleChangeShippingAddress('address2', e.target.value)}/>
                                                        <div className="invalid-feedback">{shippingErrors.address2}</div>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        }

                                    </>
                                }
                            </>
                        }

                        {/* Shipping address buttons */}
                        <div className="form-group text-end">
                            {/* Cancel button */}

                            <button type="submit" className="btn btn-success me-2">Save & Proceed
                                {processing && <span>&nbsp;...</span>}
                            </button>

                            <Button size='md' type="button" variant="warning"
                                    onClick={() => Services.redirectTo('/cart')}>Cancel</Button>
                        </div>

                    </Form>
                    <br/>
                </div>
            </Container>
        </>
    );
}

export default ShippingAddress;
